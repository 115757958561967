import {
  Box,
  ColorScheme,
  Glyph,
  H6,
  Icon,
  P,
  PlainLink,
  Space,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import { motion, Variants } from "framer-motion";
import { Trans } from "@lingui/macro";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useRouter } from "next/router";
import { isRoleAndEnvironment } from "src/common/config";
import { ConfigurationEnvironment } from "src/common/environments";
import { useDefaultCreditor } from "src/queries/creditor";
import { useSupportedBankAccountDataCreditor } from "src/common/hooks/useSupportedBankAccountDataCreditor";

import { LinkBuilder, Route } from "../routing";

const PlainLinkMotion = motion(PlainLink);
const TextMotion = motion(Text);

const TryBankAccountData = () => {
  const { theme } = useTheme();

  const initial = "initial";
  const onHover = "hover";
  const linkHoverGap: Variants = {
    [initial]: { gap: theme.spacing(0.5) },
    [onHover]: { gap: theme.spacing(0.75) },
  };
  const { sendEvent } = useSegment();
  const router = useRouter();
  const creditor = useDefaultCreditor();
  const { isCreditorSupported } = useSupportedBankAccountDataCreditor(creditor);

  const isSandboxProduction = isRoleAndEnvironment(
    ConfigurationEnvironment.SandboxProduction
  );

  // We return null for sandbox-production as Nordigen doesn't have an environment
  // that matches with it.
  if (!isCreditorSupported() || isSandboxProduction) return null;

  return (
    <Box>
      <H6 preset={TypePreset.Body_01}>Bank Payments</H6>
      <Space v={0.75} />
      <LinkBuilder
        route={Route.BankAccountData}
        onClick={() => {
          sendEvent(TrackingEvent.BANK_ACCOUNT_DATA_GO_TO_VALUE_PROP_CLICKED, {
            page: router.pathname,
            position: "sidenav",
          });
        }}
      >
        {(result) => (
          <PlainLinkMotion
            {...result}
            data-testid="try-bank-account-data"
            preset={TypePreset.Caption_01}
            initial={initial}
            whileHover={onHover}
            transition={{ duration: 0.3 }}
          >
            <Tag
              variant={TagVariant.Tinted}
              size={TagSize.Xs}
              colorScheme={ColorScheme.OnDark}
              color={TagColor.Success}
            >
              <Trans id="new">New</Trans>
            </Tag>
            <TextMotion
              variants={linkHoverGap}
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: theme.spacing(0.25),
              }}
            >
              <P>
                <Trans id="bank-account-data.try-bank-account-data">
                  Try Bank Account Data
                </Trans>
              </P>
              <Icon name={Glyph.ArrowForward} />
            </TextMotion>
          </PlainLinkMotion>
        )}
      </LinkBuilder>
    </Box>
  );
};

export default TryBankAccountData;
