import { useOrganisation } from "src/queries/organisation";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";

export const useIsEligibleForPipe = () => {
  const organisation = useOrganisation();
  const userPermissions = useUserPermissions();
  const userIsAdmin = !!userPermissions.isAdmin;

  return !!organisation?.pipe_amount && userIsAdmin;
};
