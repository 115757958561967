import {
  AlignItems,
  Box,
  Button,
  ButtonGutter,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  ColorScheme,
  FontWeight,
  Glyph,
  IconButton,
  P,
  PlainLink,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import Router from "next/router";
import { Route, getRoute } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { Trans } from "@lingui/macro";
import { useLocalStorage } from "react-use";
import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import {
  DOUBLE_REWARDS_REFERRAL_SCHEME_TERMS,
  DoubleReferralRewardEndDate,
} from "src/common/consts";
import { ReferralsSource } from "src/common/trackingEventAttributes";
import { useSegment } from "src/technical-integrations/segment/useSegment";

import { useReferralAmount } from "../routes/Refer/hooks/useReferralAmount";

const REFER_POPUP_KEY = "REFERRAL_POPUP_DISMISSED";

interface ReferPopupProps {
  setShowPopup: (e: boolean) => void;
  showPopup: boolean;
}

export const DoubleRewardsReferPopup: React.FC<ReferPopupProps> = ({
  setShowPopup,
  showPopup,
}) => {
  const { theme } = useTheme();
  const referralAmount = useReferralAmount();
  const { sendEventPromise, sendEvent } = useSegment();
  const { data: userData, mutate } = useUserShowSelf();
  const [updateUser] = useUserUpdateSelf({ onSuccess: () => mutate() });
  const [referPopupDismissed] = useLocalStorage(REFER_POPUP_KEY, String(false));

  const referPopupShowed =
    userData?.users?.dashboard_preferences?.referral_popup_dismissed ||
    referPopupDismissed === "true";
  const setReferPopupShowed = () => {
    updateUser({
      dashboard_preferences: {
        ...userData?.users?.dashboard_preferences,
        referral_popup_dismissed: true,
      },
    });
  };

  const closePopup = (target: string) => {
    sendEvent(TrackingEvent.DASHBOARD_REFER_POPUP_DISMISSED, { target });
    setShowPopup(false);
    setReferPopupShowed();
  };

  const handleTermsEvent = () =>
    sendEvent(TrackingEvent.DASHBOARD_TERMS_CLICKED);

  return !referPopupShowed && showPopup ? (
    <Box>
      <Box
        css={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          paddingTop: "50px",
          paddingBottom: "50px",
          zIndex: 2,
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      />
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "calc(100% + 8px)",
          right: 230,
          width: "300px",
          overflow: " visible !important",
          zIndex: 2,
          borderRadius: theme.spacing(1),
          borderTop: `1px solid ${theme.color(Color.White)}`,
          background: theme.color(Color.Ultraviolet_50),
          animationName: "refer-popup-animation",
          animationDuration: "0.2s",
          animationDelay: "0.3s",
          animationTimingFunction: " ease-in-out",
          animationFillMode: "both",
          ":before": {
            content: '""',
            position: "absolute",
            top: "-8px",
            left: "calc(50% - 5px)",
            width: 0,
            height: 0,
            borderLeft: `${theme.spacing(0.5)} solid transparent`,
            borderRight: `${theme.spacing(0.5)} solid transparent`,
            borderBottom: `${theme.spacing(0.5)} solid ${theme.color(
              Color.White
            )}`,
          },
        }}
      >
        <Box alignSelf={AlignItems.FlexEnd} gutterH={0.5} gutterV={0.5}>
          <IconButton
            icon={Glyph.Close}
            label="close button"
            variant={ButtonVariant.TextOnLight}
            size={{ gutters: ButtonGutter.Sm, base: ButtonSize.Sm }}
            onClick={() => closePopup("close")}
          />
        </Box>
        <Box gutterH={2} spaceBelow={2}>
          <Box spaceBelow={0.75}>
            <Tag
              variant={TagVariant.Solid}
              color={TagColor.Primary}
              colorScheme={ColorScheme.OnDark}
              size={TagSize.Lg}
              css={{
                backgroundColor: theme.color(Color.Ultraviolet_700),
                color: theme.color(Color.White),
              }}
            >
              <Trans id="double-rewards-referral.double-rewards">
                Double rewards
              </Trans>
            </Tag>
          </Box>
          <Text fontFamily="display" weight={FontWeight.Bold} size={8}>
            <Trans id="double-rewards-referral.get-incentive-when-you-refer">
              Get {referralAmount.incentive} each when you refer
            </Trans>
          </Text>
          <P
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_02}
            spaceAbove={0.75}
          >
            <Trans id="double-rewards-referral.get-double-reward">
              Get your double rewards before the offer ends on{" "}
              {DoubleReferralRewardEndDate}.
            </Trans>
            <PlainLink
              href={DOUBLE_REWARDS_REFERRAL_SCHEME_TERMS}
              target="_blank"
              decoration="underline"
              onClick={handleTermsEvent}
            >
              <Trans id="double-rewards-referral.terms-apply">
                Terms apply
              </Trans>
            </PlainLink>
          </P>

          <Box spaceAbove={2}>
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Md}
              layout={ButtonLayout.Full}
              onClick={async () => {
                await sendEventPromise(
                  TrackingEvent.DASHBOARD_REFER_CTA_CLICKED,
                  { source: ReferralsSource.POPUP }
                );
                closePopup("close");
                Router.push(getRoute(Route.Refer));
              }}
            >
              <Trans id="double-rewards-referral.refer-and-earn">
                Refer and earn
              </Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};
